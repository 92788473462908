<template>
    <div>
        <p style="font-weight: bold; text-align: center;">
            Dikkat! <br /> Bu sayfada yapacağınız değişiklikler için başvuru yapan adaya otomatik bilgilendirme maili gönderilebilir.
        </p>

        <CForm ref="form" class="needs-validation">
            <CRow>
                <CCol >
                    <CSelect
                        label="Durum"
                        type="text"
                        placeholder="Seçiniz"
                        :options="statusList"
                        :value.sync="data.status"
                        horizontal
                        required
                        @change="changeStatus"
                    />
                </CCol>
            </CRow>
            <div v-if="['in_review', 'negative', 'negative_interview' ].includes(data.status)" >
                <p style="color: red; font-weight: bold;"> 
                    Başvuru yapan adayın mevcut durumunu {{ statusList.find( e => e.value == data.status ). label }} olarak değiştirmek
                    üzeresiniz. Bu değişiklik sonucu adaya bilgilendirme maili gönderilecektir.
                </p>
                <CRow>
                    <CCol >
                        <CSelect
                            label="Mail İçerik Dili"
                            type="text"
                            placeholder="Seçiniz"
                            :options="langs"
                            :value.sync="data.lang"
                            @change="changeStatus"
                            horizontal
                            required
                        />
                    </CCol>
                </CRow>
                <CRow>
                    <CCol >
                        <CTextarea 
                            label="Mail İçeriği"
                            placeholder="Gönderilecek mail içeriğini giriniz"
                            rows="5"
                            required
                            v-model="data.content"
                        />
                    </CCol>
                </CRow>
            </div>
            
        </CForm>
    </div>
   
</template>

<script>
    import moment from 'moment'
    export default{
        name: "applicantChangeStatusForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        computed:{
            statusList: function() {
                let data = []
                this.$store.getters.careerStatusTypes.map(cst => data.push({label: cst.friendlyType, value: cst.type}))
                return data ;
            },
            langs: function() {
                let data = ['Dil Seçin']
                this.$store.getters.langs.map(r => data.push({value: r.localeCode, label: r.name}))
                return data
            },
        },
        created: function() {
            this.changeStatus()
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                this.changeStatus()
                if(val == 'create')
                    this.data = {...this.params}
            },
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
            }
        },
        methods:{
            dateFormat: function(val){
                return moment(val).format('DD.MM.YYYY HH:mm')
            },
            changeStatus: function(){
                switch(this.data.status){
                    case 'in_review':

                        if(this.data.lang == 'en')
                            this.data.content = "Merhaba, \n" + 
                            "First of all, thank you for reaching out to KAFT. \n" +
                            "We are reviewing your application; we will get back to you if the evaluation is positive. \n" +
                            "For your questions, contact us via <a href='mailto:jobs@kaft.com'>jobs@kaft.com</a> and visit " +
                            "<a href='www.kaft.com'>kaft.com</a> for more information about us." +
                            "Sevgiler, \n" +
                            "KAFT";
                        else
                            this.data.content = "Merhaba, \n" + 
                            "Öncelikle KAFT’a ulaştığın için teşekkür ederiz. \n" +
                            "Başvurunu inceliyoruz; değerlendirmenin olumlu sonuçlanması halinde tekrar irtibata geçeceğiz. \n" +
                            "Soruların olursa <a href='mailto:jobs@kaft.com'>jobs@kaft.com</a> mail adresi üzerinden bize ulaşabilir ve " +
                            "hakkımızda daha fazla bilgiye sahip olmak için <a href='www.kaft.com'>kaft.com</a> linkini ziyaret edebilirsin." +
                            "Sevgiler, \n" +
                            "KAFT";

                        break;
                        
                    case 'negative':

                        if(this.data.lang == 'en')
                            this.data.content = "First of all, thank you for reaching out to KAFT. \n" +
                            "We have evaluated your application; Unfortunately, we cannot respond positively to your application for this position.";
                        else
                            this.data.content = "Öncelikle KAFT’a ulaştığın için teşekkür ederiz. \n" +
                            "Başvurunu değerlendirdik; bu pozisyonumuz için başvuruna maalesef olumlu yanıt veremiyoruz.";

                        break;

                    case 'negative_interview':

                        if(this.data.lang == 'en')
                            this.data.content = "After our meetings, we have concluded the evaluation process. " + 
                            "While your experience and qualifications are valuable, we have decided to move forward with other candidates that match the" + 
                            "requirements for this position more closely. Therefore, we regret to inform you that we cannot offer you a position at this time. \n \n" +
                            "Thank you very much for your time and interest in KAFT." +
                            "Best Regards, \n" +
                            "KAFT";
                        else
                            this.data.content = "Görüşmelerimiz sonrasında değerlendirme sürecimiz sonuçlandı; deneyim " +
                            "ve niteliklerin değerli ancak bu pozisyonumuz için arayışımıza daha yakın olan diğer adaylarla sürece devam etme kararı aldık." +
                            "Bu nedenle maalesef olumlu dönüş yapamıyoruz.. \n \n" +
                            "KAFT’a gösterdiğin ilgi ve ayırdığın zaman için çok teşekkürler. \n" +
                            "Sevgiler, \n" +
                            "KAFT";
                        
                        break;
                    
                }
            }
        }
    }
</script>